import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  Service,
  QueryServicesResponse,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { BookingsAPI } from '../../../api/BookingsApi';

export class MapWidgetActions {
  private flowAPI: PlatformControllerFlowAPI;

  constructor({ flowAPI }) {
    this.flowAPI = flowAPI;
  }

  async getService({
    bookingsServiceId,
  }: {
    bookingsServiceId: string;
  }): Promise<Service> {
    const bookingsApi = new BookingsAPI({ flowAPI: this.flowAPI });
    const serviceResponse: QueryServicesResponse | undefined =
      await bookingsApi.getService({
        serviceId: bookingsServiceId,
      });

    return serviceResponse?.services?.[0]!;
  }
}
