import { FlowAPI } from '@wix/yoshi-flow-editor';
import {
  getMarkers,
  getLocationType,
  getEditorMarker,
  getBusinessLocationMarkers,
} from '../../../extractors/service-extractor';
import { Components } from '../../../types/components.types';
import { LocationType } from '@wix/ambassador-bookings-services-v2-service/types';

export class MapWidgetViewer {
  private components: Components;
  private flowAPI: FlowAPI;
  constructor({ components, flowAPI }) {
    this.components = components;
    this.flowAPI = flowAPI;
  }

  emptyStateView() {
    this.components.mapPluginContainer.collapse();
  }

  async markersView(service) {
    switch (getLocationType(service)) {
      case LocationType.BUSINESS:
        const markers = getMarkers(service!);
        if (markers!.length > 0) {
          this.components.googleMaps.markers = markers;
        } else {
          this.emptyStateView();
        }
        break;
      case LocationType.CUSTOM:
      case LocationType.CUSTOMER:
        this.emptyStateView();
        break;
      default:
        break;
    }
  }

  async markersBusinessLocationView(businessLocation) {
    const markers = getBusinessLocationMarkers(businessLocation);
    if (markers) {
      this.components.googleMaps.markers = [markers];
    } else {
      this.emptyStateView();
    }
  }

  editorView(isBusinessLocaiton) {
    const key = isBusinessLocaiton
      ? 'map-plugin.widget.businessLocation'
      : 'map-plugin.widget.serviceLocation';
    const markers = getEditorMarker(this.flowAPI.translations.t(key));
    this.components.googleMaps.markers = markers;
  }
}
