import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  queryLocations,
  queryServices,
} from '@wix/ambassador-bookings-services-v2-service/http';
import {
  SortOrder,
  QueryServicesRequest,
  QueryLocationsResponse,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { getAggregatedInfo } from '@wix/ambassador-bookings-catalog-v1-business-info/http';
import { EmptyStateType } from '../types/sevice-catalog.types';
import { manageWarmupData } from '../manageWarmupData/manageWarmupData';

const servicesPerPage = 100;

type QueryServicesRequestQuery = QueryServicesRequest['query'];
type QueryServicesRequestFilters = QueryServicesRequestQuery['filter'];
type QueryServicesRequestSort = QueryServicesRequestQuery['sort'];
type QueryServicesRequestPaging = QueryServicesRequestQuery['paging'];

export class BookingsAPI {
  private static cache = new Map();
  private flowAPI: PlatformControllerFlowAPI;
  constructor({ flowAPI }: { flowAPI: PlatformControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  private async withCache<T>(
    key: string,
    request: () => Promise<T> | T,
    { useWarmupData }: { useWarmupData?: boolean } = {},
  ): Promise<T> {
    const cachedResult = BookingsAPI.cache.get(key);

    if (cachedResult) {
      return cachedResult;
    }

    const result = useWarmupData
      ? await manageWarmupData(request, key, this.flowAPI)
      : await request();
    BookingsAPI.cache.set(key, result);

    return result;
  }

  static clearCache() {
    BookingsAPI.cache.clear();
  }

  async getService({ serviceId = '' }: { serviceId?: string }) {
    const filter: QueryServicesRequestFilters = serviceId
      ? {
          id: serviceId,
        }
      : {};
    const sort: QueryServicesRequestSort = [
      {
        fieldName: 'category.sortOrder',
        order: SortOrder.ASC,
      },
      {
        fieldName: 'sortOrder',
        order: SortOrder.ASC,
      },
    ];

    const paging: QueryServicesRequestPaging = {
      limit: servicesPerPage,
      offset: 0,
    };
    try {
      const response = await this.withCache(
        `queryServices-${JSON.stringify(filter)}-limit:${paging.limit}-offset:${
          paging.offset
        }`,
        () =>
          this.flowAPI.httpClient
            .request(
              queryServices({
                query: {
                  filter,
                  sort,
                  paging,
                },
              }),
            )
            .then((queryServicesResponse) => queryServicesResponse.data),
        { useWarmupData: true },
      );

      return response;
    } catch (e: any) {
      reportError(e);
      this.flowAPI.reportError(EmptyStateType.SERVER_ERROR);
    }
  }

  async getBusinessInfo() {
    const response = await this.flowAPI.httpClient.request(
      getAggregatedInfo({}),
    );

    return response.data;
  }

  async getBusinessLocation() {
    return this.withCache(
      `queryLocations`,
      () =>
        this.flowAPI.httpClient
          .request(queryLocations({}))
          .then((response) => response.data)
          .then((data: QueryLocationsResponse) =>
            data.businessLocations?.locations!.find(
              (location) => location.business?.default,
            ),
          ),
      {
        useWarmupData: true,
      },
    );
  }
}
